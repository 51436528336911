@use "@material/theme";

.site-footer{
  @include theme.property(background-color, secondary);
  @include theme.property(color, on-secondary);
  padding: .25rem 1rem;
  font-weight: bold;
  text-align: right;
  a{
    text-decoration: none;
  }
}
