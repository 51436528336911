@use "constants";
@use "@material/theme";

.links{
  .mdc-layout-grid{
    max-width: constants.$links-grid-max-width;
    @include theme.property(color, on-surface);


    .me__img{
      border-radius: 100%;
      width: 50%;
      margin-left: 25%;
      margin-right: 25%;
    }

    .links__info{
      text-align: center;
    }
  }
}
