@use "@material/theme";
@use "constants";
@use "normalize";
@use "flex";

.me{
  .mdc-layout-grid{
    max-width: constants.$me-grid-max-width;
  }

  .me__info-container{
    border-radius: 3px;
    overflow: hidden;
    display: grid;



    @include theme.property(background-color, secondary);
    @include normalize.on-phone{

      .mdc-layout-grid__inner{
        grid-gap: 0;
      }
    }

    a{
      text-decoration: none;
    }

    .me__img{
      width: 100%;
      display: block;
      grid-row: 1;
      grid-column: 1;

      
      @include normalize.on-tablet{
        width: 150px;
        max-width: 150px;
        margin-left: 2%;

      }
      @include normalize.on-desktop{
        width: 150px;
        max-width: 150px;
        margin-left: 8%;
      }
    }
  }

  .me__info-description{
      padding: 1rem;
      grid-row: 1;
      grid-column: 1/2;
      justify-self: center;

      @include normalize.on-phone{
        text-align: center;
        padding-left: .25rem;
        padding-right: .25rem;
        grid-row: 2;

      }

      .me__name{
        margin:0;
      }

      .me__email{
        display: inline-block;
        margin-bottom: 1rem * .5;
      }

      .me__links{
        @include flex.flexbox();
        @include flex.justify-content(left);
        @include flex.align-items(center);
        flex-basis: calc( (100% + 5px)/3px - 5px );
        @include normalize.on-phone{
          text-align: center;
          @include flex.justify-content(center);
        }


      .me__link-item{
        display: inline-block;
        cursor: pointer;
        font-size: 1.5rem;

        &:not(:first-child){
          margin-left: 10px;
        }

        img{
            height: 1.5rem;
            width:auto;
            cursor: pointer;
            margin-top: .5rem;
        }
      }
    }
  }

  .me__info_about{
    text-align: center;
  }
}
