@use "@material/theme";
@use "@material/top-app-bar";
@use "flex";
@use "normalize";

$site-title-font-size:rem-calc(50);
$nav-font-size:rem-calc(30);

$logo-font-size-mobile:rem-calc(45);
$nav-font-size-mobile:rem-calc(25);

$header-height:75px;
$small-margin:rem-calc(16);


.move-below-header{
  margin-top:$header-height;
}


.site-header{
  @include theme.property(background-color, secondary);
  @include theme.property(color, on-secondary);
  // height:$header-height;

  a{
    text-decoration: none;
  }
  .site-title{
      cursor: pointer;
      overflow: visible;
      position:absolute;
      left: 0;

  }

  .site-nav{
    position: absolute;
    z-index: 5;
    height: top-app-bar.$row-height - (top-app-bar.$section-vertical-padding* 2);
    width: calc(100% - #{top-app-bar.$section-horizontal-padding * 2});
    @include normalize.remove-margin();
    @include flex.flexbox();
    @include flex.justify-content(center);
    @include flex.align-items(center);

    .github-icon{
      margin-left: 1rem;
      margin-right: 1rem;
      height: 65%;
      position: absolute;
      right: 0;
      img{
        height: 100%;
      }
    }

    @media(max-width:top-app-bar.$mobile-breakpoint){
      height: top-app-bar.$mobile-row-height - (top-app-bar.$mobile-section-padding * 2);
      width: calc(100% - #{top-app-bar.$mobile-section-padding * 2});
    }

    @media (max-width: 575px){
      @include flex.justify-content(flex-end);

      .github-icon{
        height: 70%;
        position: relative;
        margin-right: .5rem;
      }
    }

    .nav-item{
      @include theme.property(color, primary);
      font-weight: bold;
      cursor: pointer;

      &:nth-child(n+2){
        margin-left:1rem;
      }

      &.current, &:hover{
        @extend .dotryan;
      }

    }
  }
}
