@use "@material/theme";
@use "constants";
@use "@material/layout-grid";


$desktop-breakpoint: map-get(layout-grid.$breakpoints, desktop);
$tablet-breakpoint: map-get(layout-grid.$breakpoints, tablet);
$phone-breakpoint: map-get(layout-grid.$breakpoints, phone);


@mixin remove-margin(){
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
}

.dotryan{
  color: constants.$white;
  font-weight:bold;
  span{
    @include theme.property(color, primary);
  }
  &.dotryan__add-dot::before{
    content: ".";
    @include theme.property(color, primary);
  }
}


@mixin on-phone{
  @media (max-width: $tablet-breakpoint) {
      @content;
  }
}

@mixin on-tablet{
  @media (min-width: $tablet-breakpoint) and (max-width: $desktop-breakpoint) {
    @content;

  }
}
@mixin on-desktop{
  @media (min-width: $desktop-breakpoint) {
    @content;
  }
}
