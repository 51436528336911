@use "theme";
@use "material-components-web/material-components-web";


///* Includes */
@use "videojs";
@use "header";
@use "card";
@use "reel-card";
@use "project-card";
@use "footer";
@use "lightbox";
@use "video-lightbox";
@use "experience-card";
@use "link-card";
//
///* Layouts */
@use "home";
@import "me";
@import "links";
//



html {
   margin:0;
   padding:0;
   height:100%;
}
body {
   min-height:100%;
   position:relative;
   margin: 0;
   line-height: 1.2;
}

/*  FOOTER ON BOTTOM */

.page-content {
   padding-bottom:2rem;   /* Height of the footer */
}
.site-footer {
   position:absolute;
   bottom:0;
   width: calc(100% - 2rem);
}
