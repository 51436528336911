@use "@material/theme";
@use  "normalize";
@use "flex";

$desktop-breakpoint-image-size: 300px;
$tablet-breakpoint-image-size: 250px;
$phone-breakpoint-image-size: auto;

$img_padding: 1rem;


.project-card{

  @include normalize.on-phone{
    flex-direction: column;
  }

  @include normalize.on-tablet{
    flex-direction: column;
  }

  @include normalize.on-desktop{
    flex-direction: column;
  }


  .project-card-media{
    flex-shrink: 0;
    border-radius: 0;

    .mdc-card__media-content{
      .mdc-layout-grid{
        padding: 1rem;

        .mdc-layout-grid__inner{
          grid-gap: 8px;

          .project-card__play_overlay{
                position: absolute;
                width: calc(100% - ($img_padding * 2));
                height: calc(100% - ($img_padding * 2));
                @include flex.flexbox();
                @include flex.justify-content(center);
                @include flex.align-items(center);
                cursor: pointer;

            .project-card__play_button {
              font-size: 10em;
              transform: translateY(.1em);

              &:before {
                content: "\f101";
                font-family: VideoJS;
                font-weight: 400;
                font-style: normal;
                @include theme.property(color, primary);

              }

            }


          }
        }
      }
    }



    img{
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 3px;
      cursor: pointer;
    }

  }
}
