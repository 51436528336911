@use "@material/theme";
@use "constants";

.link-card{
  @include theme.property(background-color, secondary);
  @include theme.property(color, constants.$white);
  padding: 15px 20px;
  position: relative;

  .title_container{
    // @include theme.property(color, $mdc-theme-secondary);
    vertical-align: middle;

    .link-card__img{
      width: 2rem;
      height: 2rem;
      vertical-align: middle;
    }

    .link-card__img_blank{
      // @include theme.property(background-color, $mdc-theme-primary);
      border-radius: 100%;
      height: 2rem;
      width: 2rem;
      display: inline-block;
      vertical-align: middle;
    }

    .title{
      font-weight: bold !important;
      line-height: 0;
      vertical-align: sub;
      margin-left: 1rem;
      @include theme.property(background-color, secondary);

    }

    .sep{
      @include theme.property(color, primary);
    }
  }




  .duration{
    position: absolute;
    top: 0px;
    right: 0px;
  }

  ul {
    list-style: none;
    padding-inline-start: 1rem;
    margin-bottom: 0;
    line-height: 1.5;
    li{
      position: relative;
      &::before{
        content: "•";
        @include theme.property(color, primary);
        display: inline-block;
        position: absolute;
        margin-left: -12px;
        margin-right: 3px;
        top: -4.5px;
        font-size: 16pt;
      }
    }
  }

}
