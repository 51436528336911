@use "@material/theme";
@use "constants";


.card-base{
  overflow: hidden;

  .card__primary{
    padding: constants.$card-primary-padding;
    .card__title{
      @include theme.property(color, white);
      margin:0;
      font-weight: bold;
    }
    .card__subtitle{
      @include theme.property(color, primary);
      margin:0;
      font-weight: bold;
    }

  }

  .card__secondary{
    padding: constants.$card-secondary-padding;
    @include theme.property(color, on-surface);

    p:first-child{
      margin: 0;
    }
  }


  &.secondary{
    @include theme.property(background-color, secondary);
    @include theme.property(color, on-secondary);

    //@include theme.property(background-color, surface);
    //@include theme.property(color, on-surface);

    .card__title{
      @include theme.property(color, on-secondary);
    }
  }
}
