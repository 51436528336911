@use "@material/theme";
@use "constants";
@use  "normalize";

.experience-card{
  @include theme.property(background-color, secondary);
  @include theme.property(color, constants.$white);
  padding: 15px 20px;
  position: relative;

  .title_container{
    // @include theme.property(color, $mdc-theme-secondary);
    .title{
      font-weight: bold !important;
    }
    .workplace{
      @include theme.property(color, primary);
      font-weight: normal;
    }
    .sep{
      @include theme.property(color, primary);
    }
  }
  
  .experience-card--content{
    cursor: default;
  }

  .duration{
    position: absolute;
    top: 0px;
    right: 0px;

    @include normalize.on-phone{
      display: none;
    }
  }


  .duration-phone{
    display: none;
    @include normalize.on-phone{
          display: initial;
    }
  }



  ul {
    list-style: none;
    padding-inline-start: 1rem;
    margin-bottom: 0;
    line-height: 1.5;
    li{
      position: relative;
      &::before{
        content: "•";
        @include theme.property(color, primary);
        display: inline-block;
        position: absolute;
        margin-left: -12px;
        margin-right: 3px;
        top: -4.5px;
        font-size: 16pt;
      }
    }
  }

}
