/* Colors */

$white: #fefefe;
$black: #0a0a0a;


$primary: #cddc39;
$on-primary: #33333D;

$secondary: #3C1759;
$on-secondary: $white;

$surface: $secondary;
$on-surface: $white;

$background: #212121;
$on-background: $white;

/* Fonts */
$font-family: Gilroy;


/* Home */
$home-grid-max-width: 70rem;
$me-grid-max-width: 60rem;

/* Links */
$links-grid-max-width: 30rem;

/* Card */

$card-primary-padding: 1rem;
$card-secondary-padding: 0 $card-primary-padding ($card-primary-padding * .5);
