@font-face {
  font-family: Gilroy;
  src:url("../fonts/Gilroy-Semibold.otf");
  font-weight: normal;
}


@font-face {
  font-family: Gilroy;
  src:url("../fonts/Gilroy-Extrabold.otf");
  font-weight: bold;
}
