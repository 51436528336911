@use "flex";
@use "normalize";
@use "slick-carousel/slick/slick.scss";
@use "slick-carousel/slick/slick-theme.scss";

/* Slick Imports */

$font-url: "../fonts/";
$image-url: "../img/";

@function image-url($url) {
  @return url($image-url + $url);
}
@function font-url($url) {
  @return url($font-url + $url);
}

/************************************/

$transparent-black: rgba(0, 0, 0, 0.55);
$gallery-index:200;

/*  SLICK OVERRIDES  */


.slick-next,
.slick-prev {
  //background: transparent url(img/arrow.svg) no-repeat center;
  border: none;
  text-indent: -9999px;
  display: block;
  outline: none;
  cursor: pointer;

    .arrow{
      width: 100%;
      height:100%;
      background: transparent url(../img/arrow.svg) no-repeat center;
      &:hover{
        background: transparent url(../img/arrow.svg) no-repeat center;

      }
    }

  $arrow-size:200;
  height: 100%;
  width: 8vw;
  position: absolute;

  z-index: $gallery-index + 20;

  opacity: .7;
  padding-left: 3%;

}
.slick-next {
  //-ms-transform: rotate(-90deg);
  //-webkit-transform: rotate(-90deg);
  //transform: rotate(-90deg);
  right: 1%;

}

.slick-prev {
  -webkit-transform: translate(0,-50%) rotate(180deg);
  -moz-transform: translate(0,-50%) rotate(180deg);
  -ms-transform: translate(0,-50%) rotate(180deg);
  -o-transform: translate(0,-50%) rotate(180deg);
  transform: translate(0,-50%) rotate(180deg);

  left: 1%;

}



.slick-prev:hover,
.slick-next:hover {
  opacity: 1;
}



/* END SLICK OVERRIDES */








#lightbox {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: $gallery-index;
  height:100%;
  width:100%;
  background: $transparent-black;
  display: none;
}

#lightbox-container {
  $height:70vw;
  position: fixed;
  left: 0;
  width: 100%;
  height:100%;
  background: $transparent-black;
  z-index: $gallery-index + 1;


  top:0;
  overflow-y: auto;
   //max-height: 75%;

  .lightbox-media-container{
    position: relative;
    width: 100%;
    z-index: $gallery-index + 2;
    @include  flex.flexbox();
    @include flex.align-items(center);
    @include flex.justify-content(center);

    top:50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);


    @include normalize.on-phone{
      $pos: 25%;
      top:$pos;
      -webkit-transform: translate(0, -$pos);
      -moz-transform: translate(0, -$pos);
      -ms-transform: translate(0, -$pos);
      -o-transform: translate(0, -$pos);
      transform: translate(0, -$pos);
    }


  }

  #lightbox-close{
    cursor: pointer;
      position: absolute;
      right:4%;
      top:4%;
      width:4vw;
      height:4vw;
      background-image: url(../img/close.svg);
      background-repeat:no-repeat;
      z-index:$gallery-index + 3;
      &:hover{
        background-image: url(../img/close.svg);
      }

    @include normalize.on-phone{
        top:5%;
        width:5vw;
        height:5vw;
      }

  }


  .lightbox-content{
    white-space:normal;
    width:100%;
    padding:0 calc(25% / 2);
    height:70%;
    margin-top: .5rem;
    margin-bottom: .5rem;
    display: inline-block;
  }

  .lightbox-title{
    text-align: center;
    margin-bottom:rem-calc(28);
  }





  #lightbox-video-container{

  }

  #lightbox-gallery-container{
    overflow: hidden;
    width:100%;
    position: relative;
    max-height: $height;

    #lightbox-gallery{
      position: relative;
      .lightbox-gallery-slide {
        display: inline-block;
        position: relative;
        //with:100%;
        //height:60vw;
        outline:none;
        .wrapper {
          position: relative;
          @include  flex.flexbox();
          @include flex.align-items(center);
          @include flex.justify-content(center);

          img {
            //height:50px;
            max-width: none;
            max-height: $height;
            //height:50vw;

            @include normalize.on-phone{
              height:80vw;
            }
          }
        }

      }
    }
  }


  #lightbox-image-container{
      position: relative;

      .lightbox-gallery-slide {
        display: inline-block;
        position: relative;
        width:100%;
        //height:60vw;


        .wrapper {
          position: relative;
          @include  flex.flexbox();
          @include flex.align-items(center);
          @include flex.justify-content(center);

          img {
            //height:50px;
            max-width: none;
            max-height: $height;
            height:$height;
          }
        }

      }

  }

}

#lightbox-lightbox-video{
  //height:100%;
  position: relative;
}
