@use "normalize";
@use "flex";

$transparent-black: rgba(0, 0, 0, 0.55);
$gallery-index: 20;

#video-lightbox {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: $gallery-index;
  height: 100%;
  width: 100%;
  background: $transparent-black;

  @include flex.flexbox();
  @include flex.justify-content(center);
  @include flex.align-items(center);


  #video-lightbox__container {
    @include normalize.on-phone {
      width: 100%;
    }

    @include normalize.on-desktop{
      width: 75%
    }


    //padding-left: 10%;
    //padding-right: 10%;
    z-index: $gallery-index + 5;

  }


  #video-lightbox-close {
    cursor: pointer;
    position: absolute;
    right: 4%;
    top: 4%;
    width: 4vw;
    height: 4vw;
    background-image: url(../img/close.svg);
    background-repeat: no-repeat;
    z-index: $gallery-index + 3;

    &:hover {
      background-image: url(../img/close.svg);
    }

    @include normalize.on-phone {
      top: 5%;
      width: 5vw;
      height: 5vw;
    }

  }


}
